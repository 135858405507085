import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import {
  immobilizeVehicle as dontDriveVehicle,
  disableVehicleAlarm as dontMakeBeepBeep,
  mobilizeVehicle as driveVehicle,
  getLiveData,
  lockHelmetBox as lockTailBox,
  enableVehicleAlarm as makeBeepBeep,
  unlockSaddle as openSeat,
  unlockHelmetBox as unlockTailBox
} from '@badgermoleV2/vehicles';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useVehicleInteraction = (vin: string = '') => {
  const queryClient = useQueryClient();

  const {
    data,
    isFetching: isLiveDataFetching,
    refetch: refetchLiveData
  } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.liveData, vin],
    queryFn: async ({ signal }) => getLiveData(vin, { signal }),
    placeholderData: keepPreviousData
  });

  const { mutateAsync: unlockHelmetBox, isPending: isUnlockHelmetBoxPending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.unlockHelmetBox, vin],
    mutationFn: async () => await unlockTailBox(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });

  const { mutateAsync: lockHelmetBox, isPending: isLockHelmetBoxPending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.lockHelmetBox, vin],
    mutationFn: async () => await lockTailBox(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });
  const { mutateAsync: disableVehicleAlarm, isPending: isDisableVehicleAlarmPending } = useMutation(
    {
      mutationKey: [MUTATION_KEYS.vehicle.disableVehicleAlarm, vin],
      mutationFn: async () => await dontMakeBeepBeep(vin),
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
    }
  );
  const { mutateAsync: enableVehicleAlarm, isPending: isEnableVehicleAlarmPending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.enableVehicleAlarm, vin],
    mutationFn: async () => await makeBeepBeep(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });
  const { mutateAsync: mobilizeVehicle, isPending: isMobilizeVehiclePending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.mobilizeVehicle, vin],
    mutationFn: async () => await driveVehicle(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });
  const { mutateAsync: immobilizeVehicle, isPending: isImmobilizeVehiclePending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.immobilizeVehicle, vin],
    mutationFn: async () => await dontDriveVehicle(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });
  const { mutateAsync: unlockSaddle, isPending: isUnlockSaddlePending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.unlockSaddle, vin],
    mutationFn: async () => await openSeat(vin),
    onSettled: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.liveData, vin] })
  });

  return {
    liveData: data,
    isLiveDataFetching: isLiveDataFetching,
    refetchLiveData,
    isVehicleInterActionPending:
      isUnlockHelmetBoxPending ||
      isLockHelmetBoxPending ||
      isDisableVehicleAlarmPending ||
      isEnableVehicleAlarmPending ||
      isMobilizeVehiclePending ||
      isImmobilizeVehiclePending ||
      isUnlockSaddlePending ||
      isUnlockHelmetBoxPending,
    isLockHelmetBoxPending,
    isDisableVehicleAlarmPending,
    isEnableVehicleAlarmPending,
    isMobilizeVehiclePending,
    isImmobilizeVehiclePending,
    isUnlockSaddlePending,
    unlockHelmetBox,
    lockHelmetBox,
    disableVehicleAlarm,
    enableVehicleAlarm,
    mobilizeVehicle,
    immobilizeVehicle,
    unlockSaddle
  };
};
